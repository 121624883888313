import { useTranslation } from 'react-i18next';

// images
import chooseYourTargetZones from './img/chooseYourTargetZones.webp';
import convenientPlayerWithTips from './img/convenientPlayerWithTips.webp';
import uniqueworkoutCreator from './img/uniqueworkoutCreator.webp';
import yourPersonalWorkoutPlan from './img/yourPersonalWorkoutPlan.webp';
import handIcon from 'assets/img/handIcon.webp';

// types
import { AtAppContainerContentType } from 'containers/AtAppContainer';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

const STARS_AMOUNT = 5;
const MARK = 4.5;
const RATINGS = 19.1;

export const getContent = (): AtAppContainerContentType => {
    const { t } = useTranslation();

    return {
        title: t('atBType.title'),
        storeLegendBlockData: {
            content: {
                starsAmount: STARS_AMOUNT,
                mark: MARK,
                ratings: RATINGS,
            },
        },
        sliderBlockData: {
            title: t('atApp.sliderBlockTitle'),
            sliderData: [
                uniqueworkoutCreator,
                yourPersonalWorkoutPlan,
                convenientPlayerWithTips,
                chooseYourTargetZones,
            ],
        },
        advantagesBlockData: {
            description: t('atApp.advantage.description'),
            title: t('atApp.advantage.title'),
            list: [
                t('atApp.advantage.list1'),
                t('atApp.advantage.list2'),
                t('atApp.advantage.list3'),
                t('atApp.advantage.list4'),
                t('atApp.advantage.list5'),
            ],
        },
        ratingAndReviewsBlockData: {
            title: t('atApp.review.title'),
            content: {
                ratingOutOfFive: MARK,
                generalRating: RATINGS,
                starsAmount: STARS_AMOUNT,
            },
            list: [
                { mark: 5, author: 'Nichole', review: t('atApp.review.list1') },
                { mark: 5, author: 'Ramo', review: t('atApp.review.list2') },
                { mark: 5, author: 'Santa', review: t('atApp.review.list3') },
                { mark: 5, author: 'Ogretmem', review: t('atApp.review.list4') },
            ],
        },
        moreInfoBlockData: {
            title: t('atApp.informationList.title'),
            list: [
                { rowTitle: t('atApp.informationList.item1.title'), value: t('atApp.informationList.item1.value') },
                { rowTitle: t('atApp.informationList.item2.title'), value: t('atApp.informationList.item2.value') },
                { rowTitle: t('atApp.informationList.item3.title'), value: t('atApp.informationList.item3.value') },
                { rowTitle: t('atApp.informationList.item4.title'), value: t('atApp.informationList.item4.value') },
                { rowTitle: t('atApp.informationList.item5.title'), value: t('atApp.informationList.item5.value') },
                { rowTitle: t('atApp.informationList.item6.title'), value: { src: handIcon }, url: PRIVACY_POLICY },
            ],
        },
    };
};
