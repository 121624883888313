import React from 'react';

export default function ArrowPrev({ className, style, onClick }: any) {
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.90136 15.0575L8.2043 16.7545L0.302734 8.85298L8.2043 0.951416L9.90136 2.64847L3.69685 8.85298L9.90136 15.0575Z"
                    fill="#8696A6"
                />
            </svg>
        </div>
    );
}
