import React from 'react';

// components
import { Text, Box } from 'components';

// styles
import styles from './AdvantagesBlock.module.css';

export type AdvantagesBlockPropsType = { mb?: number; description: string; title: string; list: string[] };

export const AdvantagesBlock = ({ mb, description, title, list }: AdvantagesBlockPropsType) => {
    return (
        <Box mb={mb}>
            <Text text={description} type="small-text" mb={16} />
            <Text text={title} bold mb={4} />
            {list.map((item) => (
                <Text text={item} key={item} type="small-text" className={styles.listItem} />
            ))}
        </Box>
    );
};
