import React from 'react';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtAgeWlContainer from 'containers/AtAgeWlContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';

// config
import { content } from './config';

function AtAgeWl() {
    const { handleClick } = useAttributionAnalytic();

    const toNextPage = (value: string) => {
        updateGlobalLoaderVisibility(true);

        handleClick(value);
    };

    return <AtAgeWlContainer toNextPage={toNextPage} optionData={content} />;
}

export default AtAgeWl;
