import React from 'react';

// components
import { Text, Image, SimpleSlider, Box } from 'components';

// type

export type SliderBlockPropsType = { mb?: number; sliderData: string[]; title: string };

export const SliderBlock = ({ sliderData, title, mb }: SliderBlockPropsType) => {
    return (
        <Box mb={mb}>
            <Text text={title} type="h5" center />
            <SimpleSlider
                content={sliderData.map((item) => (
                    <Image key={item} src={item} alt={item} maxWidth={190} />
                ))}
            />
        </Box>
    );
};
