import React from 'react';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtBTypeContainer from 'containers/AtBTypeContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';

// config
import { getContent } from './config';

function AtBType() {
    const { handleClick } = useAttributionAnalytic();

    const toNextPage = (value: string) => {
        updateGlobalLoaderVisibility(true);

        handleClick(value);
    };

    return <AtBTypeContainer toNextPage={toNextPage} content={getContent()} />;
}

export default AtBType;
