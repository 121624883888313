import React from 'react';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtAppShortContainer from 'containers/AtAppShortContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';

// config
import { getContent } from './config';

function AtAppShort() {
    const { handleClick } = useAttributionAnalytic();
    const config = getContent();

    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);

        handleClick('button text');
    };

    return <AtAppShortContainer toNextPage={toNextPage} config={config} />;
}

export default AtAppShort;
