import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionEventAction, ElementEventAction, EventTrigger, Main } from 'wikr-core-analytics';

// configs
import config from 'config';

// helpers
import { getCookie, getUrlParams, updateGlobalLoaderVisibility } from 'helpers/helpers';
import { replaceDashToUnderscore } from 'helpers/transformationUtils';

export const useAttributionAnalytic = () => {
    const location = useLocation();

    const defaultFormattedUrl = location.pathname.split('/').pop();
    const screenId = replaceDashToUnderscore(defaultFormattedUrl);

    const urlParams = JSON.stringify(getUrlParams());

    useEffect(() => {
        Main.initSystems({
            activeSystems: [
                {
                    name: 'pixel',
                    id: config.PIXEL_ID,
                },
                {
                    name: 'amazon',
                    id: config.AMAZON_ID,
                    config: {
                        release_date: config.RELEASE_DATE,
                        streamName: config.AWS_DELIVERY_STREAM_NAME,
                        envMode: config.ENV,
                    },
                },
            ],
        });

        Main.track('attribution');
        Main.trackNew({
            eventData: { event: EventTrigger.screenLoad, screenId },
            actionData: { elementEvent: ElementEventAction.screen, actionEvent: ActionEventAction.load },
        });
    }, []);

    const handleClick = (eventValue: string) => {
        Main.trackNew({
            eventData: { event: EventTrigger.click, screenId },
            actionData: { elementEvent: ElementEventAction.button, actionEvent: ActionEventAction.redirect },
            data: { event_label: eventValue },
        });

        Main.track('ViewContent', { isPrefix: false });
        Main.track('install_click_attribution', { isPrefix: false });

        const baseUrl = config.ATTRIBUTION_BASE_URL;
        const urlParamsNormalized = JSON?.parse(urlParams);
        const pid = urlParamsNormalized?.utm_source || '';
        const c = urlParamsNormalized?.utm_campaign || '';
        const af_ad_id = urlParamsNormalized?.ad_id || '';
        const af_ad = urlParamsNormalized?.ad_id || '';
        const fbp = getCookie('_fbp') ? getCookie('_fbp') : '';

        document.location = `${baseUrl}?pid=${pid}&c=${c}&af_ad_id=${af_ad_id}&af_ad=${af_ad}&fbp=${fbp}`;

        setTimeout(() => {
            updateGlobalLoaderVisibility(false);
        }, 1000);
    };

    return { handleClick };
};
