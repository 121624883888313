// images
import group1 from './img/35-44.webp';
import group2 from './img/45-54.webp';
import group3 from './img/55-64.webp';
import group4 from './img/65+.webp';

export const content = [
    {
        img: group1,
        title: '35-44',
        value: '35_44',
    },
    {
        img: group2,
        title: '45-54',
        value: '45_54',
    },
    {
        img: group3,
        title: '55-64',
        value: '55_65',
    },
    {
        img: group4,
        title: '65+',
        value: '65_and_more',
    },
];
