import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// types
import { ReviewCard, ReviewCardPropsType } from 'components/ReviewCard';
import { Text, Box, Image } from 'components';

// styles
import styles from './RatingAndReviewsBlock.module.css';

// img
import disabledStar from 'assets/img/disabledStar.webp';
import star from 'assets/img/star.webp';

export type RatingAndReviewsBlockPropsType = {
    title: string;
    content: {
        ratingOutOfFive: number;
        generalRating: number;
        starsAmount: number;
    };
    list: ReviewCardPropsType[];
    mb?: number;
};

export const RatingAndReviewsBlock = ({ title, content, list, mb }: RatingAndReviewsBlockPropsType) => {
    const { t } = useTranslation();

    return (
        <Box mb={mb}>
            <Text text={title} type="h6" mb={16} />
            <Box className={styles.legend} mb={16}>
                <Box
                    backgroundColor="surface-secondary"
                    borderRadius={8}
                    paddingX={23}
                    paddingTop={14}
                    paddingBottom={14}
                    className={styles.markWrapper}
                >
                    <Text bold center color="text-secondary-subdued">
                        <Trans
                            i18nKey="atApp.review.outOf"
                            components={{ 1: <Text type="h1" /> }}
                            count={content.ratingOutOfFive}
                        />
                    </Text>
                </Box>
                <Box className={styles.starsBlock} paddingTop={6} mb={6}>
                    <Text
                        className={styles.ratings}
                        type="caption"
                        text={t('atApp.review.rating', { rating: content.generalRating })}
                    />
                    {Array(content.starsAmount)
                        .fill(Array(content.starsAmount).fill(1))
                        .map((row, rowIndex) => (
                            <div key={rowIndex} className={styles.starsRow}>
                                <div className={styles.starsWrapper}>
                                    {row.map((item: any, starIndex: number) => (
                                        <Image
                                            maxWidth={10}
                                            src={item && rowIndex > starIndex - 1 ? star : disabledStar}
                                            key={starIndex}
                                            alt="star"
                                        />
                                    ))}
                                </div>
                                <div className={styles.progressBar} />
                            </div>
                        ))}
                </Box>
            </Box>
            {list.map((item) => (
                <ReviewCard key={item.author} {...item} mb={16} />
            ))}
        </Box>
    );
};
