import React from 'react';

// components
import { Box, Text, Image } from 'components';

// img
import star from 'assets/img/star.webp';

// styles
import styles from './ReviewCard.module.css';

export type ReviewCardPropsType = {
    author: string;
    mark: number;
    review: string;
    mb?: number;
};

export const ReviewCard = ({ author, mark, review, mb }: ReviewCardPropsType) => {
    return (
        <Box
            borderRadius={12}
            backgroundColor="surface-default"
            borderColor="border-subdued"
            paddingTop={16}
            paddingBottom={16}
            paddingX={16}
            mb={mb}
        >
            <Box mb={20} paddingTop={4} className={styles.starsWrapper}>
                {Array(mark)
                    .fill(1)
                    .map((item) => item && <Image maxWidth={16} alt="star" src={star} />)}
            </Box>
            <Text text={review} color="text-secondary" mb={16} />
            <Text type="small-text" text={author} color="text-secondary-subdued" />
        </Box>
    );
};
