import React from 'react';

// components
import { Text, Image, Box, Devider } from 'components';

// styles
import styles from './MoreInfoBlock.module.css';

type infoRowType = { rowTitle: string; value: any; url?: string };

export type MoreInfoBlockPropsType = {
    title: string;
    list: infoRowType[];
};

const RowItem = ({ rowTitle, value }: infoRowType) => (
    <Box paddingTop={12} paddingBottom={12} className={styles.listItem}>
        <Text type="caption" text={rowTitle} />
        {value?.src ? <Image maxWidth={16} src={value.src} alt={value.src} /> : <Text type="caption" text={value} />}
    </Box>
);

export const MoreInfoBlock = ({ title, list }: MoreInfoBlockPropsType) => {
    return (
        <>
            <Text type="h6" mb={12} text={title} />
            {list.map((item, index) => (
                <div key={index}>
                    {item.url ? (
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <RowItem {...item} />
                        </a>
                    ) : (
                        <RowItem {...item} />
                    )}
                    {index !== list.length - 1 && <Devider />}
                </div>
            ))}
        </>
    );
};
