import React from 'react';

// components
import { Image, Text } from 'wikr-core-components';

// img
import Next from 'assets/icons/next.svg';

// styles
import styles from './StartPageCard.module.css';

// types
import { ICard } from './types';

function StartPageCard({ onClick, img, value, title }: ICard) {
    return (
        <button onClick={(e) => onClick(e, value)} data-locator="ageButton" className={styles.card}>
            <div className={styles.imageWrapper}>
                <Image src={img} alt="people group" isForceVisible />
            </div>
            <div className={styles.buttonBox}>
                <Text type="small-button">{title}</Text>
                <Next className={styles.nextIcon} />
            </div>
        </button>
    );
}

export { StartPageCard };
