// images
import group1 from './img/18-25.webp';
import group2 from './img/26-35.webp';
import group3 from './img/36-45.webp';
import group4 from './img/46+.webp';

export const content = [
    {
        img: group1,
        title: '18-25',
        value: '18_25',
    },
    {
        img: group2,
        title: '26-35',
        value: '26_35',
    },
    {
        img: group3,
        title: '36-45',
        value: '36_45',
    },
    {
        img: group4,
        title: '46+',
        value: '46_and_more',
    },
];
