import React from 'react';
import { Theme, ThemeProvider } from 'wikr-core-components';

// services
import useI18next from './services/i18n';

// router
import Router from './router';

// helpers
import { updateGlobalLoaderVisibility } from './helpers/helpers';

// theme
import themeConfig from './themeConfig.json';

function App() {
    const { isTranslationsLoaded } = useI18next();
    // TODO: sometimes too quickly
    updateGlobalLoaderVisibility(!isTranslationsLoaded);

    const theme = themeConfig['default'].themeStyle as Theme;

    return <ThemeProvider theme={theme}>{isTranslationsLoaded ? <Router /> : null}</ThemeProvider>;
}

export default App;
