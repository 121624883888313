import { useTranslation } from 'react-i18next';

// images
import fit from './img/fit.webp';
import athletic from './img/athletic.webp';
import shapely from './img/shapely.webp';

// types
import { IAtGoalConfig } from './types';

export const getContent = (): IAtGoalConfig => {
    const { t } = useTranslation();

    return {
        optionData: [
            {
                rightImage: fit,
                value: 'fit',
                title: t('atBType.fit'),
                mb: 12,
                dataLocator: 'option',
            },
            {
                rightImage: athletic,
                value: 'athletic',
                title: t('atBType.athletic'),
                mb: 12,
                dataLocator: 'option',
            },
            {
                rightImage: shapely,
                value: 'shapely',
                title: t('atBType.shapely'),
                mb: 12,
                dataLocator: 'option',
            },
        ],
        title: t('atBType.title'),
    };
};
