import React from 'react';

// components
import { Box, Devider } from 'components';
import {
    StoreLegend,
    SliderBlock,
    AdvantagesBlock,
    RatingAndReviewsBlock,
    MoreInfoBlock,
} from 'containers/AtAppContainer/subComponents';

// types
import {
    SliderBlockPropsType,
    AdvantagesBlockPropsType,
    RatingAndReviewsBlockPropsType,
    MoreInfoBlockPropsType,
    StoreLegendPropsType,
} from 'containers/AtAppContainer/subComponents';

export type AtAppContainerContentType = {
    title: string;
    sliderBlockData: SliderBlockPropsType;
    advantagesBlockData: AdvantagesBlockPropsType;
    ratingAndReviewsBlockData: RatingAndReviewsBlockPropsType;
    moreInfoBlockData: MoreInfoBlockPropsType;
    storeLegendBlockData: StoreLegendPropsType;
};

export default function AtAppContainer({
    toNextPage,
    content,
}: {
    content: AtAppContainerContentType;
    toNextPage: () => void;
}) {
    return (
        <Box paddingX={16} paddingTop={24}>
            <StoreLegend {...content.storeLegendBlockData} onButtonClick={toNextPage} mb={20} />
            <Devider mb={24} />
            <SliderBlock {...content.sliderBlockData} mb={24} />
            <AdvantagesBlock {...content.advantagesBlockData} mb={24} />
            <Devider mb={24} />
            <RatingAndReviewsBlock {...content.ratingAndReviewsBlockData} mb={24} />
            <Devider mb={24} />
            <MoreInfoBlock {...content.moreInfoBlockData} />
        </Box>
    );
}
