import React from 'react';

// styles
import styles from './AtAppShortContainer.module.css';

// img
import bg from './img/bg.webp';

// components
import { ShortStoreLegend } from './subComponents';
import { Image } from 'wikr-core-components';

function AtAppShortContainer({ toNextPage, config }: { toNextPage: (value: string) => void; config: any }) {
    return (
        <div className={styles.wrapper}>
            <Image alt="bg" src={bg} className={styles.bg} />
            <ShortStoreLegend {...config.storeLegendBlockData} onButtonClick={toNextPage} mb={20} noPhoneBlock />
        </div>
    );
}

export default AtAppShortContainer;
