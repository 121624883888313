import React, { memo } from 'react';
import Slider from 'react-slick';

// styles
import styles from './Slider.module.css';
import './SliderCustom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// components
import ArrowNext from 'components/SimpleSlider/ArrowNext';
import ArrowPrev from 'components/SimpleSlider/ArrowPrev';

export const SimpleSlider = memo(({ content }: { content: any[] }) => {
    const settings = {
        className: styles.sliderInner,
        centerMode: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        focusOnSelect: true,
        centerPadding: '4px',
        dots: true,
        infinite: true,
        arrows: true,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext />,
        customPaging: () => <span className={styles.dot} />,
    };

    return (
        <div className="sliderWrapper">
            <Slider {...settings}>
                {content.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </Slider>
        </div>
    );
});
