import AtAgeWl from 'pages/AtAgeWl';
import AtBType from 'pages/AtBType';
import AtApp from 'pages/AtApp';
import AtAppShort from 'pages/AtAppShort';
import AtAgeChair from 'pages/AtAgeChair';

export const PUBLIC = {
    AT_AGE: {
        path: '/at-age-wl',
        component: AtAgeWl,
    },
    AT_GOAL_MM2: {
        path: '/at-btype',
        component: AtBType,
    },
    AT_APP: {
        path: '/at-app',
        component: AtApp,
    },
    AT_APP_SHORT: {
        path: '/at-app-short',
        component: AtAppShort,
    },
    AT_AGE_CHAIR: {
        path: '/at-age-chair',
        component: AtAgeChair,
    },
};

export default {
    PUBLIC,
};
