import React from 'react';

export default function ArrowNext({ className, style, onClick }: any) {
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.0986436 1.9425L1.7957 0.245449L9.69727 8.14702L1.7957 16.0486L0.0986425 14.3515L6.30315 8.14702L0.0986436 1.9425Z"
                    fill="#8696A6"
                />
            </svg>
        </div>
    );
}
