import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';

// components
import { Header, Image, Box, Text } from 'components';

// images
import logo from 'assets/img/logo.png';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

// styles
import styles from './Container.module.css';

// types
type Container = {
    children: ReactElement[];
};

function Container({ children }: Container) {
    return (
        <>
            <Header left={<Image src={logo} alt="logo" maxWidth={126} />} backgroundColor="surface-main" />
            <Box paddingX={16} paddingTop={24} paddingBottom={24}>
                {children}

                <div className={styles.fixedPolicy}>
                    <Text type="caption" center color="text-secondary-subdued">
                        <Trans
                            i18nKey="basics.privacy"
                            components={{
                                1: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                        className={styles.colorMain}
                                        data-locator="privacyPolicyLink"
                                        href={PRIVACY_POLICY}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />
                                ),
                            }}
                        />
                    </Text>
                </div>
            </Box>
        </>
    );
}

export { Container };
