import { useTranslation } from 'react-i18next';

const STARS_AMOUNT = 5;
const MARK = 4.5;
const RATINGS = 19.1;

export const getContent = () => {
    const { t } = useTranslation();

    return {
        title: t('atBType.title'),
        storeLegendBlockData: {
            content: {
                starsAmount: STARS_AMOUNT,
                mark: MARK,
                ratings: RATINGS,
            },
        },
    };
};
