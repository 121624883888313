import React from 'react';
import { Trans } from 'react-i18next';

// styles
import styles from './AtAgeChairContainer.module.css';

// components
import { Text, StartPageCard, Container } from 'components';

// types
import { IAtAgeWlContainerProps } from './types';

function AtAgeChairContainer({ toNextPage, optionData }: IAtAgeWlContainerProps) {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
        toNextPage(value);
    };

    return (
        <Container>
            <Text type="h3" center mb={16}>
                <Trans i18nKey="atAgeChair.title" />
            </Text>
            <Text type="large-text" center color="text-secondary-subdued" bold mb={20}>
                <Trans i18nKey="atAgeChair.subtitle" />
            </Text>
            <Text bold center color="text-secondary">
                <Trans i18nKey="atAgeChair.quizP" />
            </Text>
            <div className={styles.optionBox}>
                {optionData.map((card, index) => (
                    <StartPageCard
                        onClick={handleClick}
                        key={index}
                        title={card.title}
                        img={card.img}
                        value={card.value}
                    />
                ))}
            </div>
        </Container>
    );
}

export default AtAgeChairContainer;
