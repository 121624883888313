import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

// libComponents
import { OptionBox, Text, Container } from 'components';

// styles
import styles from './AtBTypeContainer.module.css';

// types
import { IAtBTypeContainer } from './types';

function AtBTypeContainer({ toNextPage, content }: IAtBTypeContainer) {
    const [selectedValue, setSelectedValue] = useState('');
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;

            return;
        }

        setTimeout(() => selectedValue && toNextPage(selectedValue), 150);
    }, [selectedValue]);

    const optionBoxHandler = (value: string) => {
        setSelectedValue(value);
    };

    return (
        <Container>
            <Text type="h3" center mb={24}>
                <Trans i18nKey={content.title} />
            </Text>
            <OptionBox onClick={optionBoxHandler} optionData={content.optionData} className={styles.wrapper} />
        </Container>
    );
}

export default AtBTypeContainer;
