import React from 'react';

// components
import { Box } from 'components';

// styles
import styles from './Devider.module.css';

export const Devider = ({ mb }: { mb?: number }) => {
    return <Box className={styles.container} backgroundColor="border-default" mb={mb} borderRadius={100} />;
};
