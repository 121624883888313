import React from 'react';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers
import AtAgeChairContainer from 'containers/AtAgeChairContainer';

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';

// config
import { content } from './config';

function AtAgeChair() {
    const { handleClick } = useAttributionAnalytic();

    const toNextPage = (value: string) => {
        updateGlobalLoaderVisibility(true);

        handleClick(value);
    };

    return <AtAgeChairContainer toNextPage={toNextPage} optionData={content} />;
}

export default AtAgeChair;
