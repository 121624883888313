import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// components
import { Text, Image, Button, Box } from 'components';

// styles
import styles from './shortStoreLegend.module.css';

// img
import StoreLogo from 'assets/img/storeLogoWhite.webp';
import star from 'assets/img/star.webp';
import halfStar from 'assets/img/halfStar.webp';
import arrow from 'assets/img/arrow.webp';
import phone from 'assets/img/phone.webp';

// constants
import { PRIVACY_POLICY } from 'constants/policy';

export type StoreLegendPropsType = {
    onButtonClick?: () => void;
    mb?: number;
    noPhoneBlock?: boolean;
    content: {
        starsAmount: number;
        mark: number;
        ratings: number;
    };
};

export const ShortStoreLegend = ({ onButtonClick, mb, noPhoneBlock, content }: StoreLegendPropsType) => {
    const { t } = useTranslation();

    return (
        <Box mb={mb} className={styles.wrapper}>
            <Box mb={20} className={styles.logoAndNamePart}>
                <Image className={styles.logo} maxWidth={90} src={StoreLogo} alt="storeLogo" />
                <div>
                    <Text type="h6" bold text={t('atApp.AppBlockTitle')} color="surface-default" />
                    <Text type="h6" bold text={t('atApp.AppBlockSubtitle')} color="surface-default" />
                    <Text type="small-text" color="border-default" text={t('atApp.AppBlockSecondSubtitle')} />
                </div>
            </Box>
            <Box className={styles.ratingWrapper} mb={22}>
                <div className={styles.rating}>
                    <Text type="large-text" bold text={`${content.mark}`} color="border-default" />
                    <div className={styles.starsWrapper}>
                        {Array(content.starsAmount)
                            .fill(1)
                            .map((item, index) => (
                                <Image
                                    alt={item}
                                    src={index === content.starsAmount - 1 ? halfStar : star}
                                    maxWidth={16}
                                    key={item}
                                />
                            ))}
                    </div>
                </div>
                <Text
                    type="caption"
                    text={t('atApp.review.rating', { rating: content.ratings })}
                    color="border-default"
                />
            </Box>
            <Button mb={22} withPulsating onClick={onButtonClick}>
                <div className={styles.buttonContent}>
                    <Text text={t('atApp.getToApp')} />
                    <Image maxWidth={16} src={arrow} alt="arrow" />
                </div>
            </Button>
            {!noPhoneBlock && (
                <Box mb={12} className={styles.phoneBlock}>
                    <Image maxWidth={24} src={phone} alt="phone" />
                    <Text type="small-text" center text={t('atApp.storeAvailable')} />
                </Box>
            )}
            <Text type="caption" center color="border-subdued">
                <Trans
                    i18nKey="basics.privacy"
                    components={{
                        1: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                                href={PRIVACY_POLICY}
                                target="_blank"
                                data-locator="privacyPolicyLink"
                                rel="noopener noreferrer"
                            />
                        ),
                    }}
                />
            </Text>
        </Box>
    );
};
